var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "misc-wrapper"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('h2', {
    staticClass: "brand-text text-primary ml-1"
  }, [_vm._v("PT Sarana Abadi Lestari")])]), _c('div', {
    staticClass: "misc-inner p-2 p-sm-3"
  }, [_c('div', {
    staticClass: "w-100 text-center"
  }, [_c('h2', {
    staticClass: "mb-1"
  }, [_vm._v("We are launching soon 🚀")]), _c('p', {
    staticClass: "mb-3"
  }, [_vm._v(" We're creating something awesome. We will Notify when it's ready! ")]), _c('b-img', {
    attrs: {
      "fluid": "",
      "src": _vm.imgUrl,
      "alt": "Coming soon page"
    }
  }), _c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('span', {
    staticClass: "text-black"
  }, [_c('small', [_vm._v(" © 2022 - " + _vm._s(new Date().getFullYear()) + " "), _c('a', {
    attrs: {
      "href": "https://github.com/yogithesymbian",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v(" Yogi Arif Widodo ")]), _vm._v(" 🎸 "), _c('a', {
    attrs: {
      "href": "https://landing.salpalaran.com/",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v(" PT Sarana Abadi Lestari ")])])])])])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }